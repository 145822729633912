<template>
  <div class="px-5" id="page-users">

    <h5 class="mb-6 ml-2">Användare</h5>

    <UsersTable
        :items="users"
        :titles="titles"
        @inviteUserClicked="inviteUser"
        @createUserClicked="createUser"
        @selectUserClicked="selectUser"
        @deleteUserClicked="deleteUser"
        @toastr="toastr"
    />
    <b-card
      title="Bjud in användare"
      tag="article"
      style="max-width: 40rem;"
      class="mb-2"
      v-if="inviting"
    >
        <b-card-text>
          Lägg till en ny användare genom att ange e-postadressen till personen nedan.
        </b-card-text>
        <b-form
          @submit.prevent="inviteEmail"
        >
          <b-form-input
            id="invite-email"
            v-model="invite_email"
            required
            placeholder="E-post"

          ></b-form-input>
          <b-button type="button" variant="primary" class="mt-2 mr-2" @click="hideInvite">Avbryt</b-button>
          <b-button type="submit" variant="primary" class="mt-2">Bjud in</b-button>
        </b-form>

    </b-card>

    <div class="scroll-to-container"></div>
    <b-card
      title=""
      class="mb-2 edit-user-container"
      ref="edit-user"
      id="edit-user"
      hide-footer
      v-if="creating || editing"
    >

    </b-card>
  </div>
</template>

<style lang="scss" scoped>

.checkbox.checkbox-light-success > span {
  background-color: white;
  border: 1px solid #ddd;
}

</style>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import UsersTable from "@/view/pages/ml/users/UsersTable.vue";

export default {
  name: 'users',
  components: {
    UsersTable
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  async mounted() {
    await this.loadData();

    this.user_id = this.$route.params.id;

    if (this.user_id === '' || this.user_id === null || this.user_id === undefined) {
      this.editing = false;
      this.creating = false;
    }
    else {
      this.editing = true;
      this.selectUser(this.user_id)
    }
  },
  data() {
    return {
      user_id: null,
      
      company: {},
      companies:[],
      form: {
          user_id: '',
          email: '',
          name: ''
      },
      invite_email: '',
      creating: false,
      editing: false,
      inviting: false,
      users: []
    };
  },
  watch: {
    
  },
  methods: {
    async loadData() {
      this.loadUsers();
    },
    inviteUser() {
      this.inviting = true;
    },
    createUser() {
      this.creating = true;
      this.editing = false;
      this.user_id = null;
      this.form = {};
      this.scrollToEditUser();
    },
    selectUser(user_id) {
      this.user_id = user_id;

      this.editing = true;

      this.scrollToEditUser();
    },
    loadUsers() {

      this.users = [];
      axios
        .get(`/users`)
        .then(res => {

          if (res.status === 200) {
            this.users = res.data;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', 'Server Fel', 'Kunde inte lista användare');
        });
    },
    scrollToClassName(classname) {
      var that = this;
      setTimeout(function(){

        const el = that.$el.getElementsByClassName(classname)[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({top: y, behavior: 'smooth'});
        }
      }, 100);
    },
    scrollToEditUser() {
      var that = this;
      setTimeout(function(){

        const el = that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({top: y, behavior: 'smooth'});
        }
      }, 100);
    },
    userCreated(form) {
      this.form = form;
      this.creating = false;
    },
    saveUser(form) {

      this.form = form;

      if (this.creating) {
        axios
          .post('/user', this.form)
          .then(res => {
            switch (res.status) {
              case 201:
                this.creating = false;
                this.toastr('success', 'OK', 'Användare skapad');
                break
              case 409:
                this.toastr('danger', 'Ogiltig e-postadress', 'Den angivna e-postadressen finns redan.');
                break
            }
          })
          .catch(err => {
            this.toastr('danger', 'Server Fel', 'Kunde inte skapa användare');
          });
      } else {

        axios
          .put('/user', this.form)
          .then(res => {
            switch (res.status) {
              case 200:
                this.toastr('success', 'OK', 'Användare uppdaterad');
                break
              case 409:
                this.toastr('danger', 'Ogiltig e-postadress', 'Den angivna e-postadressen finns redan.');
                break
            }
          })
          .catch(err => {
            console.error(err, "err");
            this.toastr('danger', 'Server Fel', 'Kunde inte uppdatera användare');
          });
      }
    },
    
    hideInvite () {
      this.inviting = false;
    },
    
    inviteEmail(e) {
      e.preventDefault();
    },

    toastr(type, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 2000,
        appendToast: true,
        variant: type,
      })
    }
  }
};
</script>
