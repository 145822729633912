<template>
  <!--begin::Advance Table Widget 9-->
  <div>
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column pl-2">
          <!-- <span class="card-label font-weight-bolder text-dark">Alla Användare</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">Nedanstående användare har tillgång till denna förening</span> -->
        </h3>
        <div class="card-toolbar">
          <a href="#" class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2" @click="inviteUserClicked"
            ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>Bjud in</a
          >
          <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click="createUserClicked"
            ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>Ny Användare</a
          >
        </div>
      </div>
    <!--end::Header-->
    <!--begin::Body-->
      <div class="card-body pt-0 table-responsive">
        <b-table
          id="template-table"
          :fields="headers"
          :items="items"
          head-variant="light"
          sticky-header
          class='mh-100'
        >
          <template #cell(id)="row">
            <div class='justify-content-end d-flex'>
              <a
                href="#"
                class="btn btn-icon btn-light btn-sm mx-3"
                @click="selectUserClicked(row.item.user_id)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                  <!--end::Svg Icon-->
                </span>
              </a>
              <a href="#" class="btn btn-icon btn-light btn-sm mr-3" @click="deleteUserClicked(row.item.user_id)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                  <!--end::Svg Icon-->
                </span>
              </a>
              
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
  <!--end::Advance Table Widget 9-->
</template>
<style lang="scss" scoped>
.checkbox.checkbox-light-success > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';


export default {
  name: "users-table",
  props: ["items"],
  components: {
    
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    inviteUserClicked() {
      this.$emit('inviteUserClicked');
    },
    createUserClicked() {
      this.$emit('createUserClicked');
    },
    selectUserClicked(user_id) {
      this.$emit('selectUserClicked', user_id);
    },
    deleteUserClicked(user_id) {
      this.$emit('deleteUserClicked', user_id);
    },
    loginUserClicked(user_id) {
      this.$emit('loginUserClicked', user_id);
    },
    toastr(type, title, message) {
      this.$emit('toastr', type, title, message);
    },
  },
  data() {
    return {
      headers: [
        {
          key: 'name',
          label: 'Namn',
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        },
        {
          key: 'email',
          label: 'E-post',
          thClass: 'w-200px',
          tdClass: 'w-200px vertical-center',
          sortable: true
        },
        {
          key: 'id',
          label: '',
          thClass: 'w-110px text-right vertical-center',
        },
      ],
      list: [

      ]
    };
  }
};
</script>
